
import { defineComponent, ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'Login',
  setup () {
    const router = useRouter()
    const store = useStore()

    // onBeforeMount(() => {})
    var formRef = ref()

    const data = reactive({
      title: '考拉项目管理系统',
      remember: true,
      form: {
        username: localStorage.login_account || '',
        password: ''
      },
      rules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      loading: false,

      // 登录
      handleLogin () {
        formRef.value.validate((valid: any) => {
          // console.log('valid', valid)
          if (valid) {
            data.loading = true

            store
              .dispatch('user/login', data.form)
              .then(() => {
                data.loading = false
                data.remember && localStorage.setItem('login_account', data.form.username)
                router.push({ path: '/' })

                ElNotification.success({
                  title: '提示',
                  message: '登录成功',
                  duration: 2000
                })
              })
              .catch(() => {
                data.loading = false
              })
          }
        })
      },

      // 一键登录
      testAccount (account: string) {
        data.form.username = account
        data.form.password = '123456'
        data.handleLogin()
      }
    })

    return {
      ...toRefs(data),
      formRef
    }
  }
})
